import React from 'react';
import { Tab } from '@mui/material';
import { styled } from '@mui/system';

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  minHeight: 30,
  minWidth: 120,
  borderRadius: 9999,
  padding: '4px 12px', // Better internal spacing
  display: 'flex',
  alignItems: 'center',
  gap: '4px', // Space between icon and text
  '&.Mui-selected': {
    backgroundColor: '#fff',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // subtle shadow
  },
}));

const TabItem = ({ ...buttonProps }) => {
  const { label, icon, ...rest } = buttonProps;

  return <StyledTab iconPosition="start" label={label} icon={icon} {...rest} />;
};

export default TabItem;
