import styled, { keyframes } from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';

import { font, mixin } from 'shared/utils/styles';

// TOOD Create a MUI style primary button
export const FurtherAIDiagnosis = styled.button`
  min-height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #0056b3;
  min-height: 30px;
  color: #fff;
  .icon {
    margin-right: 8px; // Adjust spacing as needed
  }
  ${font.size(14)}
  ${mixin.clickable}
  &:hover {
    background: #007bff;
  }
`;

const highlightKeyframes = keyframes`
  0% { background-color: rgba(255, 255, 0, 0.2); }
  100% { background-color: transparent; }
`;

export const TimelineWrapper = styled.div`
  &.active {
    animation: ${highlightKeyframes} 2s ease-out;
    border-radius: 4px;
  }
`;

export const DescriptionLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  ${font.size(15)}
  ${font.regular}
`;

export const AIDescription = styled.p`
  ${font.size(18)}
  ${font.regular}
`;

export const IssueDetailsContainer = muiStyled(Grid)({
  height: 'calc(90vh - 60px)',
  ...mixin.simpleCustomScrollbar,
});

export const Left = muiStyled(Grid)({
  height: 'calc(90vh - 60px)',
  paddingTop: '20px',
  paddingBottom: '20px',
  overflowY: 'auto',
  ...mixin.simpleCustomScrollbar,
});

export const Right = muiStyled(Grid)({
  height: 'calc(90vh - 60px)',
  paddingTop: '20px',
  paddingBottom: '20px',
  overflowY: 'auto',
  overflowX: 'hidden',
  ...mixin.simpleCustomScrollbar,
});
