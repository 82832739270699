import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';
import Grid from '@mui/material/Grid2';

import { color, font, mixin } from 'shared/utils/styles';

export const Content = styled.div`
  height: 100vh;
  padding: 0px 30px;
  overflow-x: auto;
  ${mixin.simpleCustomScrollbar}
`;

export const IssueDetailsContainer = muiStyled(Grid)({
  height: 'calc(90vh - 60px)',
  ...mixin.simpleCustomScrollbar,
});

export const Left = muiStyled(Grid)({
  height: 'calc(90vh - 60px)',
  paddingTop: '20px',
  paddingBottom: '20px',
  overflowY: 'auto',
  ...mixin.simpleCustomScrollbar,
});

export const Right = muiStyled(Grid)({
  height: 'calc(90vh - 60px)',
  overflowY: 'auto',
  ...mixin.simpleCustomScrollbar,
});

export const TopActions = styled.div`
  display: flex;
  position: 'sticky';
  top: 0;
  z-index: 1100;
  justify-content: space-between;
  padding: 20px 18px 0;
`;

export const TopActionsRight = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 4px;
  }
`;

export const SectionTitle = styled.div`
  margin: 24px 0 5px;
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(12.5)}
  ${font.bold}
`;

export const InfoLabel = styled.div`
  margin: 24px 0 8px;
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(13.5)}
  ${font.bold}
`;

export const useStyles = makeStyles((theme) => ({
  viewportModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewportModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  reportingModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  reportingModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const StampBase = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0%; // 50% is Circular shape
  border: 2px solid #e74c3c;
  color: #e74c3c; // Text color
  padding: 10px; // Padding around the text
  width: 80px; // Stamp size
  height: 30px; // Stamp size
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0); // Optional: add some shadow
`;

export const StampText = styled.div`
  width: 100%; // spans the full width of its container
  font-weight: bold; // Bold text
  font-size: 1.2rem; // Text size
  user-select: none; // Prevent text selection
  pointer-events: none; // Prevent mouse events
  letter-spacing: 0em; // spacing between characters

  text-align: center; // Center the text horizontally
  display: flex; // vertical centering
  justify-content: center; // Center content horizontally in the flex container
  align-items: center; // Center content vertically in the flex container
`;
