import styled, { css } from 'styled-components';

import {
  color,
  mixin,
  modalBorderRadius,
  zIndexValues,
} from 'shared/utils/styles';
import Icon from 'components/Icon';
import pxToRem from 'assets/theme/functions/pxToRem';

const topBarOffset = pxToRem(55);

export const ScrollOverlay = styled.div`
  z-index: ${zIndexValues.modal};
  position: fixed;
  top: ${topBarOffset};
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 1320px;
`;

export const ClickableOverlay = styled.div`
  top: ${topBarOffset};
  height: calc(100% - ${topBarOffset});
  background: rgba(0, 0, 0, 0.5);
  ${(props) => clickOverlayStyles[props.variant]}
`;

const clickOverlayStyles = {
  center: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  aside: '',
};

export const StyledModal = styled.div`
  display: inline-block;
  position: relative;
  z-index: 2000;
  overflow: auto;
  max-height: 90vh;
  max-width: 90vw;
  width: ${(props) => props.width || 'auto'};
  min-width: ${(props) => props.minWidth || 'auto'};
  background: ${(props) => (props.isPrevIssue ? '#D3D3D3' : '#fff')};
  ${(props) => modalStyles[props.variant]}
  overflow-y: hidden;
`;

const modalStyles = {
  center: css`
    max-width: ${(props) => props.width}px;
    vertical-align: middle;
    border-radius: ${modalBorderRadius.sm};
    ${mixin.boxShadowHeavy}

    // Conditionally apply top margin for isPrevIssue 
    margin-top: ${(props) => (props.isPrevIssue ? '10vh' : '0')};
  `,
  aside: css`
    min-height: 100vh;
    max-width: ${(props) => props.width}px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  `,
};

export const CloseIcon = styled(Icon)`
  position: absolute;
  font-size: 25px;
  color: ${color.textMedium};
  transition: all 0.1s;
  ${mixin.clickable}
  ${(props) => closeIconStyles[props.variant]}
`;

const closeIconStyles = {
  center: css`
    top: 10px;
    right: 12px;
    padding: 3px 5px 0px 5px;
    border-radius: 4px;
    &:hover {
      background: ${color.backgroundLight};
    }
  `,
  aside: css`
    top: 10px;
    right: -30px;
    width: 50px;
    height: 50px;
    padding-top: 10px;
    border-radius: 3px;
    text-align: center;
    background: #fff;
    border: 1px solid ${color.borderLightest};
    ${mixin.boxShadowMedium};
    &:hover {
      color: ${color.primary};
    }
  `,
};
