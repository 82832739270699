import React, { Fragment, useState } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
} from '@mui/material';
import { Button } from 'components';
import PropTypes from 'prop-types';
import { useStyles } from './Styles';
import intl from 'react-intl-universal';

const ReportSelection = ({ selectionList, title, onSelect, onClose }) => {
  const [selectedReport, setSelectedReport] = useState('');
  const classes = useStyles();
  const handleSelectChange = (event) => {
    setSelectedReport(event.target.value);
  };

  console.log('selectionList', selectionList);

  return (
    <Fragment>
      <Paper className={classes.modalContent}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <FormControl fullWidth>
          <InputLabel>
            {intl.get('issue_details_report_editor_button_select_option')}
          </InputLabel>
          <Select
            value={selectedReport}
            onChange={handleSelectChange}
            label={intl.get(
              'issue_details_report_editor_button_select_option'
            )}>
            {selectionList.map((report) => (
              <MenuItem key={report.id} value={report.id}>
                {report.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            variant="danger"
            sx={{ justifyContent: 'start' }}
            onClick={onClose}>
            {intl.get(
              'issue_details_report_editor_button_select_option_cancel'
            )}
          </Button>
          <Button
            onClick={() =>
              onSelect(
                selectionList.find((report) => report.id === selectedReport)
              )
            }
            variant="primary"
            sx={{ justifyContent: 'start' }}
            disabled={!selectedReport}>
            {intl.get(
              'issue_details_report_editor_button_select_option_confirm'
            )}
          </Button>
        </Box>
      </Paper>
    </Fragment>
  );
};

ReportSelection.propTypes = {
  selectionList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReportSelection;
