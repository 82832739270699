import React from 'react';
import { Tabs } from '@mui/material';
import { styled } from '@mui/system';
import TabItem from 'components/TabItem';
import PropTypes from 'prop-types';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  // Remove the default indicator line
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  // Give the whole Tabs container a pill shape & background
  backgroundColor: '#f5f5f5',
  borderRadius: 9999, // large borderRadius for a "pill" effect
  minHeight: 36, // control the overall height
  padding: '3px', // small padding so the selected tab "floats"
  display: 'inline-flex', // Ensures proper spacing
}));

TabsController.propTypes = {
  tabs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function TabsController({ tabs, onChange }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <StyledTabs value={value} onChange={handleChange}>
      {tabs.map((tab) => (
        <TabItem key={tab.label} label={tab.label} icon={tab.icon} />
      ))}
    </StyledTabs>
  );
}
