import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from 'components/TimelineItem';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import {
  AIDescription,
  DescriptionLink,
  FurtherAIDiagnosis,
  IssueDetailsContainer,
  Left,
  Right,
  TimelineWrapper,
} from './Styles';
import intl from 'react-intl-universal';
import IssueDetailsLoader from '../Loaders/IssueDetailsLoader';
import { InfoLabel } from '../Info/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import { Tooltip } from '@mui/material';
import AILoader from '../Loaders/AILoader';
import Info from '../Info';
import Button from 'components/Button';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { USER_DATA, LANGUAGE } from 'shared/constants/users';

export const FETCH_STATUS = {
  STARTED: 'started',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
};

const propTypes = {
  analyticData: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  issue: PropTypes.object.isRequired,
  fetchStatus: PropTypes.func.isRequired,
};

const AIFileDescription = ({ analyticData, fetchData, fetchStatus, issue }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [isSortedNewest, setIsSortedNewest] = useState(false);
  const [attachments, setAttachments] = useState(
    analyticData.data ? analyticData.data.attachmentAnalytics : []
  );

  const [startingId, setStartingId] = useState(null);

  useEffect(() => {
    let intervalId;
    if (analyticData.fetchStatus === FETCH_STATUS.STARTED) {
      fetchData();
    } else if (analyticData.fetchStatus === FETCH_STATUS.IN_PROGRESS) {
      intervalId = setInterval(() => {
        fetchStatus();
      }, 3000);
    } else {
      setStartingId(
        Math.min(
          ...analyticData.data.attachmentAnalytics.map((item) => item.id)
        ) - 1
      );
      setAttachments(analyticData.data.attachmentAnalytics);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [analyticData, fetchData, fetchStatus]);

  const addDescriptionLinks = (description, attachmentAnalytics) => {
    // Function to find matching closing parenthesis
    const findClosingParent = (str, startIndex) => {
      let count = 1;
      for (let i = startIndex + 1; i < str.length; i++) {
        if (str[i] === '(') count++;
        if (str[i] === ')') count--;
        if (count === 0) return i;
      }
      return -1;
    };

    // Function to check if string has a file extension
    const hasFileExtension = (str) => {
      return /\.[a-zA-Z0-9]+$/.test(str);
    };

    const elements = [];
    let currentIndex = 0;

    while (currentIndex < description.length) {
      const openParen = description.indexOf('(', currentIndex);
      if (openParen === -1) {
        // Split the remaining text by newlines and join with br tags
        const remainingText = description.slice(currentIndex);
        const textWithLineBreaks = remainingText
          .split('\n')
          .map((text, index, array) => (
            <Fragment key={`text-${index}`}>
              {text}
              {index < array.length - 1 && <br />}
            </Fragment>
          ));
        elements.push(textWithLineBreaks);
        break;
      }

      // Handle text before the parenthesis
      const textBeforeParen = description.slice(currentIndex, openParen);
      const textWithLineBreaks = textBeforeParen
        .split('\n')
        .map((text, index, array) => (
          <Fragment key={`text-${index}`}>
            {text}
            {index < array.length - 1 && <br />}
          </Fragment>
        ));
      elements.push(textWithLineBreaks);

      const closeParen = findClosingParent(description, openParen);
      if (closeParen === -1) {
        elements.push(description.slice(currentIndex));
        break;
      }

      const filesText = description.slice(openParen + 1, closeParen);
      const files = filesText
        .split(',')
        .map((file) => file.trim())
        .filter((file) => file.length > 0);

      elements.push('[');

      files.forEach((file, fileIndex) => {
        if (fileIndex > 0) {
          elements.push(', ');
        }

        if (hasFileExtension(file)) {
          elements.push(
            <Tooltip key={`tooltip-${openParen}-${fileIndex}`} title={file}>
              <DescriptionLink onClick={() => scrollToSection(file)}>
                {attachmentAnalytics.find((item) => item.filename === file)
                  ?.id - startingId}
              </DescriptionLink>
            </Tooltip>
          );
        } else {
          elements.push(file);
        }
      });

      elements.push(']');

      currentIndex = closeParen + 1;
    }

    return <AIDescription>{elements}</AIDescription>;
  };

  const sortAttachments = () => {
    const sortedAttachments = [...analyticData.data.attachmentAnalytics].sort(
      (a, b) => {
        return isSortedNewest
          ? new Date(b.resultMetaData.creationDate_accepted).getTime() -
              new Date(a.resultMetaData.creationDate_accepted).getTime()
          : new Date(a.resultMetaData.creationDate_accepted).getTime() -
              new Date(b.resultMetaData.creationDate_accepted).getTime();
      }
    );
    setAttachments(sortedAttachments);
    setIsSortedNewest(!isSortedNewest);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
      setActiveSection(sectionId);
      section.classList.add('active');

      setTimeout(() => {
        section.classList.remove('active');
      }, 2000);
    }
  };

  const parseDateTime = (dateTime) => {
    let formattedDateTime;
    const parsedDateTime = new Date(dateTime);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    if (
      localStorage.getItem(USER_DATA.PREFFERED_LANGUAGE) === LANGUAGE.JAPANSESE
    ) {
      formattedDateTime = parsedDateTime.toLocaleString('ja-JP', options);
    } else {
      formattedDateTime = parsedDateTime.toLocaleString('en-US', options);
    }
    return formattedDateTime;
  };

  useEffect(() => {
    if (activeSection) {
      scrollToSection(activeSection);
    }
  }, [activeSection]);

  // Move the whole thing here
  return (
    <Fragment>
      <IssueDetailsContainer container>
        {analyticData.fetchStatus !== FETCH_STATUS.COMPLETED ? (
          <Grid
            size={12}
            style={{ position: 'relative', marginTop: 20, marginBottom: 20 }}>
            <AILoader />
            <IssueDetailsLoader />
            <IssueDetailsLoader />
          </Grid>
        ) : (
          <>
            <Left size={7}>
              <Info issue={issue} />
              <Divider />
              <Grid container spacing={1}>
                <Grid size={2}>
                  <InfoLabel>
                    {intl.get('issue_details_info_label_ai_summary')}
                  </InfoLabel>
                </Grid>
                <Grid size={10}>
                  {addDescriptionLinks(
                    analyticData.data.issueAnalytics.result.summary,
                    analyticData.data.attachmentAnalytics
                  )}
                </Grid>
              </Grid>
            </Left>
            <Right size={5}>
              {/* TODO Add button to sort attachments */}
              <Grid container>
                <Grid size={8}>
                  <InfoLabel>
                    {intl.get('issue_details_info_label_file_timeline')}
                  </InfoLabel>
                </Grid>
                <Grid>
                  <Button
                    variant="primary"
                    sx={{ justifyContent: 'start' }}
                    onClick={sortAttachments}
                    icon={<SwapVertIcon />}>
                    {isSortedNewest
                      ? intl.get('issue_details_button_sort_by_newest')
                      : intl.get('issue_details_button_sort_by_oldest')}
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}>
                  {attachments.map((item, _) => {
                    const { filename, filetype, resultMetaData, result, id } =
                      item;
                    console.log('resultMetaData', resultMetaData);
                    return (
                      <TimelineWrapper
                        id={`${filename}`}
                        key={id}
                        className={activeSection === filename ? 'active' : ''}>
                        <TimelineItem
                          index={id - startingId}
                          extension={filetype}
                          title={filename}
                          dateTime={parseDateTime(
                            resultMetaData.creationDate_accepted
                          )}
                          description={result.summary}
                          isBadgeVisible={!resultMetaData.patientName_matches}
                          patientNameLearnt={resultMetaData.patientName_learnt}
                        />
                      </TimelineWrapper>
                    );
                  })}
                </Timeline>
              </Grid>
            </Right>
          </>
        )}
      </IssueDetailsContainer>
    </Fragment>
  );
};

AIFileDescription.propTypes = propTypes;

export default AIFileDescription;
