import DocloudTemplate from './DocloudTemplate';
import intl from 'react-intl-universal';

export const REPORT_TEMPLATE_LIST = [
  {
    id: 1,
    title: intl.get('issue_details_report_editor_template_docloud'),
    template: DocloudTemplate,
  },
];
