import {
  TimelineItem as MuiTimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
} from '@mui/lab';
import PropTypes from 'prop-types';
import { Badge, Tooltip, Typography } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import intl from 'react-intl-universal';
import { FileIcon } from 'react-file-icon';

// Create the icons and their map
const iconMap = {
  zip: 'compressed',
  dcm: 'image',
  pdf: 'document',
  txt: 'text',
  hl7: 'document',
  xlsx: 'spreadsheet',
  xls: 'spreadsheet',
  csv: 'spreadsheet',
  json: 'binary',
  xml: 'binary',
  yaml: 'binary',
  ini: 'binary',
};

const iconColorMap = {
  compressed: 'blue',
  image: 'purple',
  document: 'red',
  text: 'green',
  binary: 'orange',
};

const propTypes = {
  extension: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isBadgeVisible: PropTypes.bool,
  patientNameLearnt: PropTypes.string,
};

const TimelineItem = ({
  index,
  extension,
  title,
  dateTime,
  description,
  isBadgeVisible,
  patientNameLearnt,
}) => (
  <MuiTimelineItem>
    <TimelineSeparator>
      <TimelineDot color="inherit">
        <Badge
          badgeContent={
            <Tooltip title={patientNameLearnt}>
              <PriorityHighIcon
                fontSize="small"
                sx={{ fontSize: '0.8rem', color: '#fff' }}
              />
            </Tooltip>
          }
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: 'red', // Custom color
              width: 22, // Making it more rounded
              height: 22, // Ensuring a perfect circle
              borderRadius: '50%', // Full round shape
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Adds slight depth
            },
          }}
          invisible={!isBadgeVisible}
          color="error"
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <div
            style={{
              width: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <FileIcon
              extension={extension}
              type={iconMap[extension] ?? 'document'}
              color={iconColorMap[iconMap[extension]] ?? 'blue'}
            />
          </div>
        </Badge>
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography variant="h6" component="span">
        {`[${index}] ${title}`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{
          fontSize: '0.675rem', // or '14px' - adjust this value as needed
        }}>
        {dateTime}
      </Typography>
      <Typography variant="body2">{description}</Typography>
    </TimelineContent>
  </MuiTimelineItem>
);

TimelineItem.propTypes = propTypes;
export default TimelineItem;
