import React, { Fragment, useState } from 'react';

import { Button } from 'components';
import { ShareIssueButtonMenu } from 'components/Menu';
import intl from 'react-intl-universal';

const ShareIssueButton = ({ ...buttonProps }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = React.useRef(null);
  const open = Boolean(anchorEl);
  const {
    setIssueShareConfirmModalOpen,
    shareIssueConfirmModalBodyText,
    shareIssueConfirmFunc,
    shareIssueCancelFunc,
    shareIssueServiceType,
  } = { ...buttonProps };

  const handleClick = () => {
    setAnchorEl(ref.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <div ref={ref}>
        <Button icon="feedback" onClick={handleClick} {...buttonProps}>
          {intl.get('issue_details_button_share')}
        </Button>
      </div>
      <ShareIssueButtonMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        setIssueShareConfirmModalOpen={setIssueShareConfirmModalOpen}
        shareIssueConfirmModalBodyText={shareIssueConfirmModalBodyText}
        shareIssueConfirmFunc={shareIssueConfirmFunc}
        shareIssueCancelFunc={shareIssueCancelFunc}
        shareIssueServiceType={shareIssueServiceType}
      />
    </Fragment>
  );
};

export default ShareIssueButton;
