import { Typography } from '@mui/material';
import { Overlay } from './Styles';
import Spinner from 'components/Spinner';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Add the status of the current AI
const propTypes = {
  status: PropTypes.string.isRequired,
};

const AILoader = ({ status }) => {
  // TODO pass status and then show the correct text

  return (
    <Overlay>
      <Spinner size={70} />
      <Typography variant="h6" style={{ marginTop: '10px' }}>
        {intl.get('issue_details_ai_loader_text')}
      </Typography>
    </Overlay>
  );
};

AILoader.propTypes = propTypes;

export default AILoader;
