import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  reportingModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  reportingEditorModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '55vw',
    height: '95vh',
  },

  reportingEditorModalPaperWithPreview: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '85vw',
    height: '95vh',
  },
  reportingPreviewModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '60vw',
    height: '95vh',
  },
}));
