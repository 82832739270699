import React, { useState } from 'react';

import { copyToClipboard } from 'shared/utils/browser';
import { Button } from 'components';
import intl from 'react-intl-universal';

const CopyLinkButton = ({ ...buttonProps }) => {
  const [isLinkCopied, setLinkCopied] = useState(false);
  const { serviceType } = buttonProps;

  const handleLinkCopy = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    const origin = window.location.origin;
    const path = window.location.pathname;
    const pathList = path.split('/');
    const issueId = pathList[pathList.length - 1];
    const linkPrefix = `${origin}${process.env.REACT_APP_ROUTE_BASENAME}/browse/issues`;
    let link = `${linkPrefix}/${issueId}?serviceType=${serviceType}`;
    if (pathList[1] === 'research') {
      link = window.location.href;
    }
    copyToClipboard(link);
  };

  return (
    <Button icon="link" onClick={handleLinkCopy} {...buttonProps}>
      {isLinkCopied
        ? intl.get('issue_details_button_link_copied')
        : intl.get('issue_details_button_copy_link')}
    </Button>
  );
};

export default CopyLinkButton;
